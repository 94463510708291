<template>
  <b-container fluid class="bv-example-row">
    <b-navbar toggleable="sm" type="light" variant="light">
      <b-navbar-toggle target="nav-text-collapse"></b-navbar-toggle>
      <b-navbar-brand href="/">PORTAL V4.3</b-navbar-brand>
      <b-collapse id="nav-text-collapse" is-nav>
        <b-navbar-nav>
          <b-nav-item href="/list">Товары</b-nav-item>
          <b-nav-item href="/list2">Товары 2.1</b-nav-item>
          <b-nav-item href="/feedback">Отзывы</b-nav-item>
          <b-nav-item href="/logs-prices">Лог изменения цен</b-nav-item>
        </b-navbar-nav>
      </b-collapse>

    </b-navbar>
    <b-row class="mt-2">
      <b-col>
        <router-view />
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {data: () => ({title: 'title'}), name: "Home-app"};
</script>
